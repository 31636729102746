var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DriverEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "מספר טלפון", "read-only": _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.PhoneNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "PhoneNumber", $$v)
          },
          expression: "model.PhoneNumber"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "ת.ז", "read-only": _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.IdNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "IdNumber", $$v)
          },
          expression: "model.IdNumber"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שם מלא", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Name,
          callback: function($$v) {
            _vm.$set(_vm.model, "Name", $$v)
          },
          expression: "model.Name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מייל", readOnly: _vm.readOnly, type: "email" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Email,
          callback: function($$v) {
            _vm.$set(_vm.model, "Email", $$v)
          },
          expression: "model.Email"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מספר רישיון נהיגה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.LicenseNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "LicenseNumber", $$v)
          },
          expression: "model.LicenseNumber"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "מנהל",
          filterable: false,
          clearable: false,
          optionsAsync: _vm.getDrivers,
          "null-is-value": true,
          readOnly: _vm.readOnly,
          required: "true"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.ManagerId,
          callback: function($$v) {
            _vm.$set(_vm.model, "ManagerId", $$v)
          },
          expression: "model.ManagerId"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "קוד מערכת ניתור", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.TrackerDriverId,
          callback: function($$v) {
            _vm.$set(_vm.model, "TrackerDriverId", $$v)
          },
          expression: "model.TrackerDriverId"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "עיר", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.City,
          callback: function($$v) {
            _vm.$set(_vm.model, "City", $$v)
          },
          expression: "model.City"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "רחוב", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Street,
          callback: function($$v) {
            _vm.$set(_vm.model, "Street", $$v)
          },
          expression: "model.Street"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מספר בית", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.HouseNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "HouseNumber", $$v)
          },
          expression: "model.HouseNumber"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מיקוד", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.ZipCode,
          callback: function($$v) {
            _vm.$set(_vm.model, "ZipCode", $$v)
          },
          expression: "model.ZipCode"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }