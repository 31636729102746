<template>
  <div class="Drivers__View">
    <EntityListLayout
      ref="entity"
      :tabs="tabs"
      @onNewEntitySave="onNewEntitySave"
    >
      <template #title>נהגים</template>
      <template #button>
        <b-button @click="showNewEntity">הוספת נהג חדש</b-button>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import DriverService from "@/services/DriverService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import DriverEntity from "@/views/DriverEntity.vue";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
} from "buefy";
import { saveAs } from "file-saver";
import NewDriverEntity from "@/components/Entities/NewDriverEntity.vue";

export default {
  name: "Drivers",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
  },
  created() {
    const driversFields = FieldService.list("drivers");
    const driverFilters = FilterService.list();

    const sideViewEntity = {
      component: DriverEntity,
      preview: true,
      readOnly: true,
      deletable: {
        action: ({ model, loading, close }) => {
          Dialog.confirm({
            type: "is-danger",
            message: "האם אתה בטוח שברצונך למחוק נהג זה?",
            onConfirm: () => {
              loading(true);
              DriverService.deleteDriver(model.Id)
                .then(() => {
                  Toast.open({
                    type: "is-success",
                    message: "הפעולה בוצעה בהצלחה!",
                    duration: 10000,
                  });
                  close();
                })
                .catch(() => {
                  Toast.open({
                    type: "is-danger",
                    message: "לא היה ניתן לבצע את הפעולה",
                    duration: 8000,
                  });
                })
                .finally(() => {
                  loading(false);
                });
            },
            confirmText: "בטוח!",
            cancelText: "בטל",
          });
        },
      },
      title: "פרטי לקוח",
      recordUrl: "drivers",
    };
    const excelExport = [
      {
        title: "יצוא טבלה",
        action: this.export,
      },
    ];
    const fields = [
      { ...driversFields.IdNumber, checked: true, Sortable: true },
      { ...driversFields.Name, checked: true, Sortable: true },
      { ...driversFields.PhoneNumber, checked: true, Sortable: true },
      { ...driversFields.Email, checked: false, Sortable: true },
      { ...driversFields.City, checked: true, Sortable: true },
      { ...driversFields.Street, checked: true },
      { ...driversFields.HouseNumber, checked: true },
      { ...driversFields.ZipCode, checked: true },
      { ...driversFields.DriverNumber, checked: true },
      { ...driversFields.LicenseNumber, checked: true },
      { ...driversFields.TrackerDriverId, checked: true },
      { ...driversFields.HasDriversLicense, checked: true },
      { ...driversFields.HasTransferForm, checked: true },
      { ...driversFields.IsManager, checked: false, Sortable: true },
      { ...driversFields.IsActive, checked: false, Sortable: true },
    ];

    const filters = [
      { ...driverFilters.IdNumber },
      { ...driverFilters.FullName },
      { ...driverFilters.PhoneNumber },
      { ...driverFilters.Email },
      { ...driverFilters.City },
      { ...driverFilters.WorkerNumber },
      { ...driverFilters.TrackingIdNumber },
      { ...driverFilters.IsDriverLicenseExist },
      { ...driverFilters.ManagerId },
      { ...driverFilters.IsActive },
    ];

    this.tabs = [
      {
        id: "MyDrivers",
        title: "הנהגים שלי",
        getDataAsync: this.getMyDrivers,
        newEntityComponent: NewDriverEntity,
        perPage: 20,
        fields,
        filters,
        sortField: "DateTaken",
        sortOrder: "desc",
        fastSearch: true,
        checkableRows: false,
        sideViewEntity,
        excelExport,
      },
    ];
  },
  methods: {
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading }) {
      loading(true);
      DriverService.exportXsl()
        .then((r) => {
          this.saveFile(r.data, "יצוא נהגים ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    getMyDrivers({ cancelToken, ...rest }) {
      return DriverService.search(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
  },
};
</script>
