<template>
  <div class="New__Entity">
    <div class="SideMenu__Header">
      <h1>הוספת נהג</h1>
    </div>
    <div class="SideMenu__Content">
      <FieldInlineText
        label="תעודת זהות"
        @onChange="onChange"
        v-model="model.IdNumber"
        :required="$v.model.IdNumber.$error"
      />
      <span class="error" style="color: red" v-if="$v.model.IdNumber.$error">אנא הזן תעודת זהות תקינה</span>

      <FieldInlineText label="שם" @onChange="onChange" v-model="model.Name" :required="$v.model.Name.$error" />

      <FieldInlineText
        label="מספר סלולרי"
        @onChange="onChange"
        v-model="model.PhoneNumber"
      />
      <FieldInlineText
        label="מייל"
        @onChange="onChange"
        v-model="model.Email"
        :required="$v.model.Email.$error"
      />
      <span class="error" style="color: red" v-if="$v.model.Email.$error">אנא הזן מייל תקין</span>

      <FieldInlineText
        label="מספר רשיון נהיגה"
        @onChange="onChange"
        v-model="model.LicenseNumber"
      />

      <FieldInlineSelect
        :hide-hover="true"
        label="מנהל"
        :clearable="true"
        :filterable="true"
        :null-is-value="true"
        placeholder="בחר מנהל מהרשימה"
        :optionsAsync="getManagers"
        @onChange="onChange"
        v-model="model.ManagerId"
      />

      <FieldInlineText
        label="מספר עובד בארגון"
        @onChange="onChange"
        v-model="model.DriverNumber"
      />

      <FieldInlineText
        label="קוד מערכת ניתור"
        @onChange="onChange"
        v-model="model.TrackerDriverId"
      />

      <FieldInlineText label="עיר" @onChange="onChange" v-model="model.City" />

      <FieldInlineText
        label="רחוב"
        @onChange="onChange"
        v-model="model.Street"
      />

      <FieldInlineText
        label="מספר בית"
        @onChange="onChange"
        v-model="model.HouseNumber"
      />

      <FieldInlineText
        label="מיקוד"
        @onChange="onChange"
        v-model="model.ZipCode"
      />
    </div>
    <b-notification
      v-model="entityIsAlreadyExists"
      type="is-info"
      class="notification"
      aria-close-label="Close notification"
    >
      הנהג כבר קיים במערכת, על מנת לטעון את הנהג הקיים
      <a @click.prevent="loadExist" href=""> לחצו כאן</a>
    </b-notification>
    <div class="SideMenu__Footer">
      <div>
        <button :disabled="isLoading" @click="onCancel" class="close" type="">
          ביטול
        </button>
        <b-button
          :loading="isLoading"
          @click="onSave(false)"
          class="create"
          type=""
        >
          שמור
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import DriverService from "@/services/DriverService";
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";

export default {
  name: "NewDriverEntity",
  components: {
    FieldInlineSelect,
    FieldInlineText,
  },
  validations: {
    model: {
      Name: {
        required,
      },
      IdNumber: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(10),
      },
      Email: {
        email
      }
    },
  },
  data() {
    return {
      entityIsAlreadyExists: false,
      isLoading: false,
      model: {
        Name: null,
        ManagerId: null,
        TrackerDriverId: null,
        Street: null,
        HouseNumber: null,
        City: null,
        IdNumber: null,
        LicenseNumber: null,
        ZipCode: null,
        PhoneNumber: null,
        Email: null,
        DriverNumber: null,
      },
    };
  },
  methods: {
    onChange() {},
    getManagers() {
      return DriverService.get;
    },
    onSave() {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      const data = { ...this.model };
      data.ManagerId = this.model.ManagerId?.Value;

      DriverService.postDriver(data)
        .then((r) => {
          self.$buefy.toast.open({
            type: "is-success",
            message: `הנהג נשמר בהצלחה. לצפיה בנהג <a style="color:white;text-decoration: undeline;" href="drivers/${r.data.Id}">לחץ כאן </a>`,
            duration: 10000,
          });
          self.$emit("onSave");
        })
        .catch((err) => {
          let msg = "";
          switch (err.response?.data?.ErrorCode) {
            case 28:
              // eslint-disable-next-line no-unused-expressions
              msg = "קיים נהג במערכת.";
              break;
            case 39:
              // eslint-disable-next-line no-unused-expressions
              msg = "עדכון לא מותר לנהג.";
              break;
            case 40:
              // eslint-disable-next-line no-unused-expressions
              msg = "יש להזין מספר טלפון";
              break;
            default:
              msg = "השמירה נכשלה, יש לנסות שוב.";
          }
          self.$buefy.toast.open({
            position: "is-bottom",
            type: "is-danger",
            message: msg,
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
          DriverService.get(true); // cache clear for filters
        });
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style scoped lang="scss">
.notification {
  position: absolute;
  bottom: 43px;
  width: 100%;
  border-radius: 0;

  ::v-deep .media-content {
    text-align: center;
  }

  &.is-info {
    background-color: #01afb4;
  }
  // &.is-warrent {
  //     background-color: orange;
  // }
}
.SideMenu__Footer {
  justify-content: space-between;
}
.New__Entity {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
