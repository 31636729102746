var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Driver__Entity" },
    [
      _c("Entity", {
        ref: "entity",
        attrs: {
          "entity-type": "Driver",
          "entity-id": _vm.driverId,
          innerComponent: _vm.innerComponent,
          innerComponentTitle: "פרטי נהג",
          title: "נהג",
          "back-button-text": "רשימת נהגים",
          "back-button-url": "drivers",
          widgets: _vm.widgets,
          deletion: _vm.deletion,
          preview: _vm.preview,
          "read-only": _vm.readOnly,
          entityReady: _vm.entityReady,
          activities: []
        },
        on: { onFormSave: _vm.onFormSave, onFormDiscard: _vm.onFormDiscard },
        scopedSlots: _vm._u([
          {
            key: "mandatory",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("שם הנהג:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Name))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מספר טלפון:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.PhoneNumber))]
                    )
                  ]
                ),
                _vm.Email
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "Accident__Entity__Details__BaseDetails__Stage"
                      },
                      [
                        _c("span", { staticClass: "ltr" }, [_vm._v("מייל:")]),
                        _c(
                          "span",
                          {
                            staticClass:
                              "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                          },
                          [_vm._v(_vm._s(_vm.Email))]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }