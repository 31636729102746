<template>
  <div class="Driver__Entity">
    <Entity
      ref="entity"
      entity-type="Driver"
      :entity-id="driverId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי נהג"
      title="נהג"
      back-button-text="רשימת נהגים"
      back-button-url="drivers"
      :widgets="widgets"
      :deletion="deletion"
      :preview="preview"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="[]"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">שם הנהג:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Name }}</span
          >
        </div>

        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר טלפון:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ PhoneNumber }}</span
          >
        </div>
        <div v-if="Email" class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מייל:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Email }}</span
          >
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
// import DriverService from '@/services/DriverService';
import Entity from "@/components/Entity.vue";
import DriverEntityDetails from "@/components/DriverEntityDetails.vue";
// import EntityMixin from '@/mixins/EntityMixin';
import { mapActions, mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import DriverService from "@/services/DriverService";
import ReportService from "@/services/ReportService";
import WidgetService from "../services/WidgetService";

const { mapFields } = createHelpers({
  getterType: "driver/getField",
  mutationType: "driver/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly", "deletion"],
  computed: {
    ...mapState("driver", ["Name", "PhoneNumber", "Email"]),
    driverId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      return [
        {
          ...WidgetService.DriverTransferform,
          namespace: "driver",
          documentType: "TransferForm",
          title: "טופס הצהרת נהג",
          hasDocument: this.data?.Driver.HasTransferForm,
        },
        {
          ...WidgetService.DriverTransferform,
          namespace: "driver",
          documentType: "DriversLicenseHtml",
          title: "צילום רשיון נהיגה",
          hasDocument: this.data?.Driver.HasDriversLicenseHtml,
        },
        {
          ...WidgetService.DriverContracts,
          namespace: "driver",
          title: "חוזים",
        },
        {
          ...WidgetService.Reports,
          namespace: "driver",
          query: () => ({ DriverId: this.driverId, IsPaid: false }),
          noData: "אין דוחות פתוחים",
          delegate: ReportService.search
        },
        {
          ...WidgetService.Reports,
          namespace: "driver",
          query: () => ({ DriverId: this.driverId, TransferStatus: [10, 11] }),
          title: "הסבות",
          noData: "אין הסבות",
        },
        {
          ...WidgetService.TollRoadsDrives,
          namespace: "driver",
          query: () => ({ LinkedDriverId: this.driverId, Status: [1] }),
          noData: "אין נסיעות כבישי אגרה",
        },
      ];
    },
    innerComponent() {
      return DriverEntityDetails;
    },
    ...mapFields(["Driver"]),
  },
  data() {
    return {
      entityReady: false,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.driverId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      DriverService.updateDriver(this.driverId, {
        ...this.$store.getters["driver/form"],
        ...model,
      })
        .then(() => {
          this.init();
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          closeDialog();
          saving(false);
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "driver/loadDriver",
    }),
  },
};
</script>

<style scoped lang="scss">
</style>
